import React from 'react'
import { graphql } from 'gatsby'

import Layout from 'components/Layout'
import Slider from 'components/Slider'
import SiteMetadata from 'query/SiteMetadata'

const Index = ({ data }) => {
  const { booking: bookingLink } = SiteMetadata().links

  return (
    <Layout pageTitle='Hem'>
      <oma-grid-row fullWidth>
        <Slider imageNodes={data.backgrounds.nodes} className='slider' />
      </oma-grid-row>
      <oma-grid-row>
        <section className='section section--small-top-space'>
          <h1 className='heading page__heading slider__text'>
            Vi hjälper dig med besvär i muskler och leder!
          </h1>
          <a
            className='link link--theme link--large link--button'
            href={bookingLink}
          >
            Boka en tid
          </a>
          <h2 className='heading section__heading'>Välkommen!</h2>
          <p className='section__text'>
            Här på NaprapatAkuten i Tierp kan du få hjälp med besvär från
            muskler och leder. Om du har råkat ut för ryggskott, nackspärr,
            ischias, trötthet i ryggen Du kanske får ont i nacke axlar när du
            sitter för länge vid datorn. Vi kan även hjälpa dig med olika
            idrottsskador som stukade fötter, muskelbristningar och problem med
            knän och axlar. Du får även hjälp med vad du skall göra för att
            undvika att få tillbaka besvären om du t.ex. behöver styrketräna,
            stretcha eller tänka på hur du sitter/står på jobbet.
          </p>
          <p className='section__text'>Hjärtligt välkommen.</p>
        </section>
      </oma-grid-row>
      <oma-grid-row>
        <div className='section'>
          <h2 className='heading section_heading'>Aktuellt</h2>
          <p className='section__text'>
            Vi vill gärna informera om att Elisabeth Blomkvist numera heter
            Widén i efternamn vilket ni kommer se när ni bokar tid hos henne.
          </p>
        </div>
      </oma-grid-row>
    </Layout>
  )
}

export const query = graphql`
  {
    backgrounds: allFile(filter: { relativeDirectory: { eq: "slider" } }) {
      nodes {
        relativePath
        childImageSharp {
          gatsbyImageData(
            width: 1050
            height: 550
            quality: 80
            layout: CONSTRAINED
          )
        }
      }
    }
  }
`

export default Index

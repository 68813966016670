import { graphql, useStaticQuery } from 'gatsby'

export default () => {
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          menuItems {
            title
            link
          }
          links {
            booking
          }
        }
      }
    }
  `)

  return site.siteMetadata
}

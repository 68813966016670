import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'

const Slider = ({ children, className, imageNodes }) => {
  return (
    <div className={className}>
      <oma-content-switch milliseconds-per-slide='6000' transition='fade'>
        {imageNodes.map((node) => (
          <GatsbyImage image={node.childImageSharp.gatsbyImageData} />
        ))}
      </oma-content-switch>
      {children}
    </div>
  )
}

export default Slider
